import styles from '@/components/template/footer/FooterAddress/FooterAddress.module.scss'

const FooterAddress = () => {
  return (
    <div className={styles.root}>
      <p className="mb-2">
        <span className={styles.title}>Standort Winterthur</span>
        <span className={styles.subTitle}>Reisen</span>
        Surprise Kultour AG
        <br />
        Rudolfstrasse 37
        <br />
        8400 Winterthur
      </p>
      <p className="mb-4">
        <span className={styles.country}>Tel</span>
        <a href="tel:0041714145080" data-tr-click="footer:contact_phone:click">
          +41 (0)71 414 50 80
        </a>
      </p>
      <p className="mb-2">
        <span className={styles.title}>Standort Sommeri</span>
        <span className={styles.subTitle}>Busbetrieb</span>
        Surprise Kultour AG
        <br />
        Hauptstrasse 33
        <br />
        8580 Sommeri TG
      </p>
      <p className="mb-4">
        <span className={styles.country}>Tel</span>
        <a href="tel:0041714145090" data-tr-click="footer:contact_phone:click">
          +41 (0)71 414 50 90
        </a>
      </p>
      <p className="mb-4">
        <a href="mailto:info@surprise-reisen.ch" data-tr-click="footer:contact_email:click">
          info@surprise-reisen.ch
        </a>
      </p>
    </div>
  )
}

export default FooterAddress
